<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :placeholder="'Nhập mã, họ tên ...'" :fields="fields" @load="load" @reset="reset"
                     title="Danh sách người khuyết tật" titleIcon="fa fa-user-injured">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='search' />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="types"  :options="$params.patient.typeOptions" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="degree_disability"  :options="$params.patient.degreeDisabilityOptions" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="agent_orange_victim"  :options="$params.patient.victimOptions" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="gender_id"  :options="$params.gender" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="mine"  :options="$params.patient.mineVictimOptions" :isFilter="true" @change="search" />
                    <form-select-multiple :close-on-select='true' class="mr-2" :model="filterFormModel" :placeHolder="'Chọn loại hình dịch vụ'" attribute="type_patient" :options="$params.patientOther.typeOptions" :isFilter="true" @change="search" />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(fullname)="{item}">
                    <div v-if="item.fullname"><span class="small text-gray-999">Mã: </span>{{item.code}}</div>
                    {{item.fullname}}
                </template>
                <template #cell(info)="{item}">
                    <div v-if="item.dob"><span class="small text-gray-999">Năm sinh: </span>{{item.dob | moment('YYYY')}}</div>
                    <div><span class="small text-gray-999">Giới tính: </span>{{item.gender_id == 0 ? 'Nam' : item.gender_id == 1 ? 'Nữ' : 'Khác'}}</div>
                    <div v-if="item.race_id" ><span class="small text-gray-999">Dân tộc: </span> 
                        <span v-if='params.race && params.race[item.race_id]'>{{params.race[item.race_id]}}</span>
                    </div>
                    <div ><span class="small text-gray-999">Nơi sinh sống: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id].name}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id].name}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id].name}}</span>
                    </div>
                </template>
                <template #cell(in)="{item}">
                    <div ><span class="small text-gray-999">Can thiệp PHCN: </span>{{params.phcn[item.id] ? params.phcn[item.id] : 0}}</div>
                    <div ><span class="small text-gray-999">Can thiệp chăm sóc: </span>{{params.care[item.id] ? params.care[item.id] : 0}}</div>
                    <div ><span class="small text-gray-999">Can thiệp tâm lý: </span>{{params.mentality[item.id] ? params.mentality[item.id] : 0}}</div>
                    <div ><span class="small text-gray-999">Hỗ trợ xã hội khác: </span>{{params.integrate[item.id] ? params.integrate[item.id] : 0}}</div>
                </template>
                <template #cell(types)="{item}">
                    <div v-if="params.tys[item.id]" ><span class="small text-gray-999">Dạng khuyết tật: </span> 
                        <span v-for="tys_id in params.tys[item.id]" :key="tys_id" >
                            <span class="badge bg-gray ml-1" v-if='$params.patient.typeOptions.find(o => o.value === tys_id)'>{{ $params.patient.typeOptions.find(o => o.value === tys_id).text}}</span>
                        </span>
                    </div>
                    <div v-if="item.agent_orange_victim" ><span class="small text-gray-999">Nạn nhân da cam: </span> 
                        <span   v-if='$params.patient.victimOptions.find(o => o.value === item.agent_orange_victim)'>{{ $params.patient.victimOptions.find(o => o.value === item.agent_orange_victim).text}}</span>
                    </div>
                    <div v-if="item.degree_disability" ><span class="small text-gray-999">Mức độ khuyết tật: </span> 
                        <span   v-if='$params.patient.degreeDisabilityOptions.find(o => o.value === item.degree_disability)'>{{ $params.patient.degreeDisabilityOptions.find(o => o.value === item.degree_disability).text}}</span>
                    </div>
                    <div v-if="item.mine_victim" ><span class="small text-gray-999">Nạn nhân bom mìn: </span> 
                        <span   v-if='$params.patient.mineVictimOptions.find(o => o.value === item.mine_victim)'>{{ $params.patient.mineVictimOptions.find(o => o.value === item.mine_victim).text}}</span>
                    </div>
                    <div v-if="params.startTimes[item.id]" >
                        <div v-if="params.startTimes[item.id].start_time && params.startTimes[item.id].start_time > 0"><span class="small text-gray-999" >Ngày bắt đầu can thiệp: </span>{{params.startTimes[item.id].start_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                        <div v-if="params.startTimes[item.id].end_time && params.startTimes[item.id].end_time > 0"><span class="small text-gray-999" >Ngày kết thúc can thiệp: </span>{{params.startTimes[item.id].end_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                        <div v-if="params.startTimes[item.id].rate_time && params.startTimes[item.id].rate_time > 0"><span class="small text-gray-999" >Ngày can thiệp gần nhất: </span>{{params.startTimes[item.id].rate_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    </div>
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :prime="isPrime() || isTW() || isUsaid()" :degreeDisabilityOptions="$params.patient.degreeDisabilityOptions" :victimOptions="$params.patient.victimOptions" :params="params" :mineVictimOptions="$params.patient.mineVictimOptions" :totalCount="itemCount" :types="params.tys" class="mr-1" />
                    <g-button class="ml-2" v-if="permissions('patient_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(action)="{item}">
                    <btn-relative :patient="item" class="ml-1 mb-1" />
                    <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('patient_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                    <g-button  v-if="permissions('patient_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="patient" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã NKT" :required='true' :model="formModel" attribute="code"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-select-multiple :required='true'  :close-on-select='false' label="Dạng khuyết tật" :model="formModel" attribute="types" :errors="formErrors" :options="patientTypeOptions" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-date-time :required='true'  label="Năm sinh" :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-select :required='true'  label="Giới tính" :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md-4'>
                    <parameter-select label="Dân tộc" :type="$params.const.race.key"  :model="formModel" attribute="race_id" />
                </div>
            </div>
            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>

            <div class='row'>
                <div class='col-md'>
                    <form-select :required='true'  label="Nạn nhân da cam" :model="formModel" attribute="agent_orange_victim" :errors="formErrors" :options="$params.patient.victimOptions" />
                </div>
                <div class='col-md'>
                    <form-select :required='false'  label="Nạn nhân bom mìn" :model="formModel" attribute="mine_victim" :errors="formErrors" :options="$params.patient.mineVictimOptions" />
                </div>
                <div class='col-md'>
                    <form-select :required='true'  label="Mức độ khuyết tật" :model="formModel" attribute="degree_disability" :errors="formErrors" :options="$params.patient.degreeDisabilityOptions" />
                </div>
            </div>

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='lg' :title="formTitle"   cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã NKT" disabled  :model="formModel" attribute="code"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" disabled :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-select-multiple  disabled  :close-on-select='false' label="Dạng khuyết tật" :model="formModel" attribute="types" :errors="formErrors" :options="patientTypeOptions" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-date-time   disabled label="Năm sinh" :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-select  disabled  label="Giới tính" :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md-4'>
                    <parameter-select disabled label="Dân tộc" :type="$params.const.race.key"  :model="formModel" attribute="race_id" />
                </div>
            </div>
            <form-group-address :provinceDisabled="true" :districtDisabled="true" :wardDisabled="true" class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
            <div class='row'>
                <div class='col-md'>
                    <form-select  disabled  label="Nạn nhân da cam" :model="formModel" attribute="agent_orange_victim" :errors="formErrors" :options="$params.patient.victimOptions" />
                </div>
                <div class='col-md'>
                    <form-select :required='false' disabled  label="Nạn nhân bom mìn" :model="formModel" attribute="mine_victim" :errors="formErrors" :options="$params.patient.mineVictimOptions" />
                </div>
                <div class='col-md'>
                    <form-select  disabled  label="Mức độ khuyết tật" :model="formModel" attribute="degree_disability" :errors="formErrors" :options="$params.patient.degreeDisabilityOptions" />
                </div>
            </div>

            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ClientSelect from '@/components/ClientSelect';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import ParameterSelect from '@/components/ParameterSelect';
    import Log from "@/components/Log";
    import BtnRelative from "./BtnPatientRelative";
    import Export from './PatientExport';

    export default {
        components: {ClientSelect, ProvinceSelect, ParameterSelect, Log, BtnRelative, Dropdown, Export},
        mixins: [list, crud, role],
        data: function () {
            return {
                reloadBus: "reloadPatient",
                service: '/patient',
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    types: null,
                    degree_disability: null,
                    agent_orange_victim: null,
                    gender_id: null,
                    mine: null,
                    type_patient: null,
                    client_sub_id: null,
                },
                defaultFormModel: {
                    types: [],
                    agent_orange_victim: null,
                    degree_disability: null,
                    code: null,
                    race_id: null,
                    gender_id: null,
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                    mine_victim: null,
                },
                showFormView: false,
                patientTypeOptions: [],
                placeholder: "Test",

            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'fullname', label: "Người khuyết tật"},
                    {key: 'info', label: "Thông tin NKT"},
                    {key: 'types', label: "Thông tin khác"},
                    {key: 'in', label: "Thống kê"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.toUpperCase().trim();
                    }
                }, deep: true
            },
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    if (this.beforeUpdate) {
                        this.beforeUpdate(response.data);
                    }
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin NKT: " + this.formModel.fullname;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            beforeUpdate(data) {
                data.dob = data.dob ? data.dob * 1000 : null;
            },
            getPatientTypeOption(){
                for (let i in this.$params.patient.typeOptions){
                    let id = this.$params.patient.typeOptions[i];
                    if(id.value != null){
                        this.patientTypeOptions.push(id);
                    }  
                }
                return this.patientTypeOptions;
            },
        },
        mounted(){
            this.getPatientTypeOption();
        },
    }
</script>