<template>
    <button class="btn btn-outline-gray btn-sm" @click="open">
        <i class="fa fa-users" ></i> Xem người thân

        <b-modal v-model="listForm" size="xl" content-class="modal-auto" :title="`Người thân của NKT ${patient.fullname}`" ok-only ok-variant="secondary" ok-title="Đóng" no-close-on-backdrop no-enforce-focus>
            <patient-relative :patientIdChild="patient.id" :showFormPatient="showFormPatient"/>
        </b-modal>
    </button>
</template>

<script>
    import PatientRelative from './PatientRelative';

    export default {
        props: ["patient"],
        components: {PatientRelative},
        data() {
            return {
                listForm: false,
                showFormPatient: false,
            };
        },
        watch: {
            "listForm": {
                handler(val) {
                    if (!val) {
                        this.$bus.$emit("reloadPatient");
                    }
                }, deep: true
            }
        },
        methods: {
            open() {
                this.listForm = true;
                this.showFormPatient = true;
            },
        },
    };
</script>
