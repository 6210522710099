<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';

    export default {
        props: ['filter', 'totalCount', 'params', 'types', "degreeDisabilityOptions", "victimOptions", "prime", "mineVictimOptions"],
        mixins: [Export],
        data() {
            return {
                service: '/patient',
                fileName: `Danh sach nguoi khuyet tat`,
                headers: this.prime ? [
                    {header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 26},
                    {header: 'code_local', key: 'code_local',label: 'Mã định danh', width: 30},
                    {header: 'code', key: 'code', label: 'Mã NKT', width: 26},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 17},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 20},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 29},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 29},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 29},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính', width: 15},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)', width: 40},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam', width: 24},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật', width: 21},
                    {header: 'mine_victim', key: 'mine_victim', label: 'Nạn nhân bom mìn', width: 21},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp', width: 21},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất', width: 21},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp', width: 21},
                    {header: 'phcn', key: 'phcn', label: 'Thống kê can thiệp PHCN', width: 21},
                    {header: 'care', key: 'care', label: 'Thống kê can thiệp chăm sóc', width: 21},
                    {header: 'mentality', key: 'mentality', label: 'Thống kê can thiệp tâm lý', width: 21},
                    {header: 'integrate', key: 'integrate', label: 'Thống kê hỗ trợ hòa nhập', width: 21},
                ] : [
                    {header: 'code_local', key: 'code_local',label: 'Mã định danh', width: 30},
                    {header: 'code', key: 'code', label: 'Mã NKT*', width: 26},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 17},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 20},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 29},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 29},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 29},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính', width: 15},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)', width: 40},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam', width: 24},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật', width: 21},
                    {header: 'mine_victim', key: 'mine_victim', label: 'Nạn nhân bom mìn', width: 21},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp', width: 21},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất', width: 21},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp', width: 21},
                    {header: 'phcn', key: 'phcn', label: 'Thống kê can thiệp PHCN', width: 21},
                    {header: 'care', key: 'care', label: 'Thống kê can thiệp chăm sóc', width: 21},
                    {header: 'mentality', key: 'mentality', label: 'Thống kê can thiệp tâm lý', width: 21},
                    {header: 'integrate', key: 'integrate', label: 'Thống kê hỗ trợ hòa nhập', width: 21},
                ],
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);


                let provinces = [];
                var tb = this.params.provinceImport;
                for (const key in tb) {
                    provinces.push(key + ". " + tb[key]);
                }


                row = {};
                row["code_local"] = "Mã định danh"
                row["code"] = "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)";
                row["fullname"] = "Nhập định dạng chữ (tối đa 200 ký tự)";
                row["dob"] = "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu) (yyyy)";
                row["province_id"] = "Nhập định dạng chữ (" + provinces.toString() + ")";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row["race_id"] = "Nhập định dạng chữ";
                row["gender_id"] = "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)";
                row["types"] = "Nhập 01 hoặc nhiều giá trị số từ 1 đến 6 (Trong đó: " + this.$params.patient.typeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["agent_orange_victim"] = "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.victimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["degree_disability"] = "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: " + this.$params.patient.degreeDisabilityOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["mine_victim"] = "Nhập giá trị số từ 1 đến 2 (Trong đó: 1. Có/2. Không)";
                row['start_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu";
                row['rate_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp";
                row['end_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp";
                row['phcn'] = "Số lần";
                row['care'] = "Số lần";
                row['mentality'] = "Số lần";
                row['integrate'] = "Số lần";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };


            },
            addRow(item, params) {
                let row = {
                    client_id: params.clients && params.clients[item.client_id] ? params.clients[item.client_id].short_name : '',
                    code_local: item.code_by_local ? item.code_by_local.toString() : '',
                    code: item.code,
                    fullname: item.fullname,
                    dob: item.dob != 0 ? moment(item.dob * 1000).format('YYYY'): '',
                    province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id].name : '',
                    district_id: params.districts && params.districts[item.district_id] ? params.districts[item.district_id].name : '',
                    ward_id: params.wards && params.wards[item.ward_id] ? params.wards[item.ward_id].name : '',
                    gender_id: item.gender_id == 0 ? 'Nam' : item.gender_id == 1 ? 'Nu' : 'Khac',
                    race_id: params.race[item.race_id],
                    types: this.getTypes(params.tys[item.id]),
                    agent_orange_victim: item.agent_orange_victim && this.victimOptions.find(o => o.value == item.agent_orange_victim) ? this.victimOptions.find(o => o.value == item.agent_orange_victim).text : "",
                    degree_disability: item.degree_disability && this.degreeDisabilityOptions.find(o => o.value == item.degree_disability) ? this.degreeDisabilityOptions.find(o => o.value == item.degree_disability).text : "",
                    mine_victim: item.mine_victim && this.mineVictimOptions.find(o => o.value == item.mine_victim) ? this.mineVictimOptions.find(o => o.value == item.mine_victim).text : "",
                    start_time: params.startTimes[item.id] && params.startTimes[item.id].start_time > 0 ? moment(params.startTimes[item.id].start_time * 1000).format('DD/MM/YYYY') : '',
                    rate_time: params.startTimes[item.id] && params.startTimes[item.id].rate_time > 0 ? moment(params.startTimes[item.id].rate_time * 1000).format('DD/MM/YYYY') : '',
                    end_time: params.startTimes[item.id] && params.startTimes[item.id].end_time > 0 ? moment(params.startTimes[item.id].end_time * 1000).format('DD/MM/YYYY') : '',
                    phcn: params.phcn[item.id] ? params.phcn[item.id] : 0,
                    care: params.care[item.id] ? params.care[item.id] : 0,
                    mentality: params.mentality[item.id] ? params.mentality[item.id] : 0,
                    integrate: params.integrate[item.id] ? params.integrate[item.id] : 0,
                };
                return row;
            },
            getTypes(data) {
                let text = "";
                if (data) {
                    for (let item of data) {
                        text = text + this.$params.patient.typeOptions.find(o => o.value == item).text + "; ";
                    }
                }
                return text.substring(0, text.length - 2);
            },
        }
    }
</script>